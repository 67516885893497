<template>
  <div>
    <v-dialog
      v-model.trim="showCreateLeadForm"
      max-width="600"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <slot name="activator" />
        </div>
      </template>
      <v-card>
        <v-card-title
          class="pa-0"
        >
          <v-toolbar
            color="primary"
            dark
            flat
          >
            <v-toolbar-title>{{ $t('labels.create-contact') }}</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                icon
                dark
                @click="hideDialog"
              >
                <v-icon large>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>

        <v-card-text class="dialog-content-overflow">
          <CreateLeadForm
            :lead-data="lead.personalData"
            :country="country"
            @createdLeadResult="handleCreatedResult"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="hideDialog"
          >
            {{ $t('labels.cancel') }}
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="submitCreateLeadForm"
          >
            {{ $t('labels.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import IDENTITY from '@/queries/Identity.gql'

import CreateLeadForm from './CreateLeadForm.vue'
import bus, { eventNames } from '@/lib/eventBus'

const initialUserState = (country) => ({
  personalData: {
    lastname: null,
    firstname: null,
    email: null,
    phone: null,
    isDataProcessingPermissionGranted: false
  }
})

export default {
  components: {
    CreateLeadForm
  },
  props: {
    refetchParent: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      isValid: false,
      showCreateLeadForm: false,
      lead: initialUserState(this.country)
    }
  },
  computed: {
    country () {
      return this.identity?.company?.country
    }
  },
  methods: {
    resetLeadData () {
      // reset
      bus.$emit(eventNames.RESET_CREATE_LEAD_FORM)
      this.lead = initialUserState(this.country)
    },
    hideDialog () {
      this.showCreateLeadForm = false
      this.resetLeadData()
    },
    submitCreateLeadForm () {
      this.$tracking.event('Settings', this.$tracking.trackingEvents.CLICKED, 'Create Lead')
      bus.$emit(eventNames.SUBMIT_CREATE_LEAD_FORM)
    },
    handleCreatedResult ({ createLeadByUser }) {
      bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'success', text: this.$t('alerts.create-lead.success') })
      this.$emit('created', createLeadByUser)
      this.refetchParent()
      this.hideDialog()
    }
  },
  apollo: {
    identity: {
      query: IDENTITY,
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      }
    }
  }
}
</script>

<style scoped>
.dialog-content-overflow{
  max-height: 500px;
  overflow: scroll
}
</style>
