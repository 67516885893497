<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.firstname"
        outlined
        validate-on-blur
        :label="$t('labels.first-name')"
        hide-details="auto"
        @click="onClick('First Name', leadData.firstname)"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.lastname"
        outlined
        validate-on-blur
        :label="$t('labels.name')"
        hide-details="auto"
        @click="onClick('Last Name', leadData.lastname)"
      />
    </v-col>
    <v-col cols="12">
      <v-text-field
        v-model.trim="leadData.email"
        outlined
        :rules="[rules.required, rules.email]"
        validate-on-blur
        :label="$t('labels.email-required')"
        type="email"
        hide-details="auto"
        @click="onClick('Email', leadData.email)"
      />
    </v-col>
    <v-col
      cols="12"
      class="pb-0"
    >
      <PhoneField
        v-model="leadData.phone"
        class="pb-0"
      />
    </v-col>

    <v-col
      cols="12"
      class="my-4"
    >
      <v-checkbox
        v-model="leadData.isDataProcessingPermissionGranted"
        class="mt-0"
        :rules="[rules.required]"
        :label="$t('labels.data-processing-granted-required')"
        @click="onClick('Consent to process contact data exists', leadData.isDataProcessingPermissionGranted)"
      />
    </v-col>
  </v-row>
</template>
<script>
import { required, email } from '@/lib/validation'
import PhoneField from '@/components/PhoneField.vue'
export default {
  components: {
    PhoneField
  },
  props: {
    leadData: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    rules () {
      return {
        email,
        required
      }
    }
  },
  methods: {
    onClick (label, value) {
      this.$tracking.event('Leads', this.$tracking.trackingEvents.CLICKED, label, value)
    }
  }
}
</script>
